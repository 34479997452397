import { SUBJECTS } from '~/data/constants';

export const PAGE_TYPES = {
  homeSchool: 'home',
  oge: 'oge',
  ege: 'ege',
  tutors: 'tutors',
  skills: 'skills',
  it: 'it',
  class10: '10-11',
  class8: '8-9',
  class5: '5-7',
  class1: '1-4',
  kids: 'kids',
  adult: 'adult',
};

export const MODAL_TYPES = {
  attachment: 'attachment',
  certificate: 'certificate',
  college: 'college',
  cost: 'cost',
  course: 'course',
  demo: 'demo',
  discount30: 'discount30',
  discount: 'discount',
  egeVuz: 'egeVuz',
  freeLesson: 'freeLesson',
  freePrograming: 'freePrograming',
  languages: 'languages',
  modal14: 'modal14',
  ogeCollege: 'ogeCollege',
  preschooler: 'preschooler',
  psychologist: 'psychologist',
  secondDiploma: 'secondDiploma',
  secondEducation: 'secondEducation',
  skills: 'skills',
  skillsGreen: 'skillsGreen',
  soft: 'soft',
  synergyBox: 'synergyBox',
  synergyBoxGreen: 'synergyBoxGreen',
  triz: 'triz',
  vuz: 'vuz',
  suitableTraining: 'suitableTraining',
  menuConsult: 'menuConsult',
  transfer: 'transfer',
  proftest: 'proftest',
  plan: 'plan',
  checkList: 'checkList',
  fizmatClass: 'fizmatClass',
  languageClass: 'languageClass',
  gymClass: 'gymClass',
  econClass: 'econClass',
  profClassConsult: 'profClassConsult',
  socGroups: 'socGroups',
  externalBanner: 'externalBanner',
};

export const LINKS = {
  [PAGE_TYPES.homeSchool]: {
    school: {
      title: 'школа',
      items: [
        { name: 'домашняя школа', to: '/online-school' },
        { name: 'it школа', to: '/school-university?class=2' },
        { name: 'физмат класс', to: '/online-school/fiziko-matematicheskij' },
        { name: 'гуманитарный класс', to: '/online-school/gumanitarnyj' },
        { name: 'лингвистический класс', to: '/online-school/lingvisticheskij' },
        { name: 'соц. эконом класс', to: '/online-school/socialno-ekonomicheskij' },
        { name: 'экстернат для детей', to: '/externat' },
        { name: 'экстернат для взрослых', to: '/externat-dlya-vzroslyh' },
      ],
    },
    age: {
      title: 'возраст',
      items: [
        { name: '11 класс', to: '/online-school/11-klass' },
        { name: '10 класс', to: '/online-school/10-klass' },
        { name: '9 класс', to: '/online-school/9-klass' },
        { name: '8 класс', to: '/online-school/8-klass' },
        { name: '7 класс', to: '/online-school/7-klass' },
        { name: '6 класс', to: '/online-school/6-klass' },
        { name: '5 класс', to: '/online-school/5-klass' },
        { name: '1-4 класс', modal: MODAL_TYPES.preschooler },
        { name: 'дошкольникам', modal: MODAL_TYPES.preschooler },
      ],
    },
    useful: {
      title: 'полезное',
      items: [
        { name: 'стоимость', modal: MODAL_TYPES.cost },
        { name: 'получение аттеста', modal: MODAL_TYPES.certificate },
        { name: 'прикрепиться к школе', modal: MODAL_TYPES.attachment },
        { name: 'документы', to: '/documents' },
        { name: 'как получить скидку?', modal: MODAL_TYPES.discount },
      ],
    },
    free: {
      title: 'бесплатно',
      items: [
        { name: 'доступ к платформе', pretext: 'бесплатный', modal: MODAL_TYPES.demo },
        { name: 'профориентация', to: '/proforientacia' },
      ],
    },
  },
  [PAGE_TYPES.oge]: {
    subject: {
      title: 'предмет',
      items: [
        { name: 'математика', to: `/oge/${SUBJECTS.matematika}` },
        { name: 'русский язык', to: `/oge/${SUBJECTS.russkijYazyk}` },
        { name: 'литература', to: `/oge/${SUBJECTS.literatura}` },
        { name: 'английский язык', to: `/oge/${SUBJECTS.anglijskijYazyk}` },
        { name: 'физика', to: `/oge/${SUBJECTS.fizika}` },
        { name: 'химия', to: `/oge/${SUBJECTS.himiya}` },
        { name: 'биология', to: `/oge/${SUBJECTS.biologiya}` },
        { name: 'информатика', to: `/oge/${SUBJECTS.informatika}` },
        { name: 'история', to: `/oge/${SUBJECTS.istoriya}` },
        { name: 'обществознание', to: `/oge/${SUBJECTS.obshchestvoznanie}` },
      ],
    },
    services: {
      title: 'услуги',
      items: [
        { name: 'курсы огэ', to: '/oge' },
        { name: 'репетитор по огэ', to: '/catalog/repetitor/oge' },
        { name: 'профильные программы', to: '/school-university' },
        { name: 'огэ и поступление', modal: MODAL_TYPES.college },
        { name: 'школьный психолог', modal: MODAL_TYPES.psychologist },
      ],
    },
    free: {
      title: 'бесплатно',
      items: [
        { name: 'профориентация', to: '/proforientacia' },
        { name: 'по любому предмету', pretext: 'пробное занятие', modal: MODAL_TYPES.freeLesson },
        { name: '«итоговое собеседование»', to: '/itogovoe-sobesedovanie-2024' },
        { name: 'как получить скидку?', modal: MODAL_TYPES.discount },
      ],
    },
  },
  [PAGE_TYPES.ege]: {
    subject: {
      title: 'предмет',
      items: [
        { name: 'математика', to: `/ege/${SUBJECTS.matematika}` },
        { name: 'русский язык', to: `/ege/${SUBJECTS.russkijYazyk}` },
        { name: 'литература', to: `/ege/${SUBJECTS.literatura}` },
        { name: 'английский язык', to: `/ege/${SUBJECTS.anglijskijYazyk}` },
        { name: 'физика', to: `/ege/${SUBJECTS.fizika}` },
        { name: 'химия', to: `/ege/${SUBJECTS.himiya}` },
        { name: 'биология', to: `/ege/${SUBJECTS.biologiya}` },
        { name: 'информатика', to: `/ege/${SUBJECTS.informatika}` },
        { name: 'история', to: `/ege/${SUBJECTS.istoriya}` },
        { name: 'обществознание', to: `/ege/${SUBJECTS.obshchestvoznanie}` },
      ],
    },
    services: {
      title: 'услуги',
      items: [
        { name: 'курсы егэ', to: '/ege' },
        { name: 'репетитор по егэ', to: '/catalog/repetitor/ege' },
        { name: 'профильные программы', to: '/school-university' },
        { name: 'егэ и поступление', modal: MODAL_TYPES.vuz },
        { name: 'школьный психолог', modal: MODAL_TYPES.psychologist },
      ],
    },
    free: {
      title: 'бесплатно',
      items: [
        { name: 'профориентация', to: '/proforientacia' },
        { name: 'по любому предмету', pretext: 'пробное занятие', modal: MODAL_TYPES.freeLesson },
        { name: '«итоговое сочинение»', to: '/itogovoe-sochinenie' },
        { name: 'как получить скидку?', modal: MODAL_TYPES.discount },
      ],
    },
  },
  [PAGE_TYPES.tutors]: {
    subject: {
      title: 'предмет',
      items: [
        { name: 'математика', to: '/catalog/repetitor/matematika' },
        { name: 'русский язык', to: '/catalog/repetitor/russkij-yazyk' },
        { name: 'литература', to: '/catalog/repetitor/literatura' },
        { name: 'физика', to: '/catalog/repetitor/fizika' },
        { name: 'химия', to: '/catalog/repetitor/himiya' },
        { name: 'биология', to: '/catalog/repetitor/biologiya' },
        { name: 'окружающий мир', to: '/catalog/repetitor/okruzhayushchij-mir' },
        { name: 'география', to: '/catalog/repetitor/geografiya' },
        { name: 'информатика', to: '/catalog/repetitor/informatika' },
        { name: 'история', to: '/catalog/repetitor/istoriya' },
        { name: 'обществознание', to: '/catalog/repetitor/obshchestvoznanie' },
      ],
    },
    languages: {
      title: 'иностранные языки',
      items: [
        { name: 'английский язык', to: '/catalog/repetitor/anglijskij_yazyk' },
        { name: 'китайский язык', to: '/catalog/repetitor/kitajskij-yazyk' },
        { name: 'немецкий язык', to: '/catalog/repetitor/nemezkij-yazyk' },
        { name: 'испанский язык', to: '/catalog/repetitor/ispanskij-yazyk' },
      ],
    },
    age: {
      title: 'возраст',
      items: [
        { name: '1-4 класс', to: '/catalog/repetitor/1-class' },
        { name: '5 класс', to: '/catalog/repetitor/5-class' },
        { name: '6 класс', to: '/catalog/repetitor/6-class' },
        { name: '7 класс', to: '/catalog/repetitor/7-class' },
        { name: '8 класс', to: '/catalog/repetitor/8-class' },
        { name: '9 класс', to: '/catalog/repetitor/9-class' },
        { name: '10 класс', to: '/catalog/repetitor/10-class' },
        { name: '11 класс', to: '/catalog/repetitor/11-class' },
      ],
    },
    goal: {
      title: 'цели',
      items: [
        { name: 'помочь с д/з', to: '/catalog/repetitor/pomoch-s-dz' },
        { name: 'подготовиться к егэ', to: '/catalog/repetitor/ege' },
        { name: 'подготовиться к огэ', to: '/catalog/repetitor/oge' },
        { name: 'подготовиться к олимпиадам', to: '/catalog/repetitor/olympiada' },
        { name: 'подтянуть знания', to: '/catalog/repetitor/podtyanut-znaniya' },
      ],
    },
    free: {
      title: 'бесплатно',
      items: [
        { name: 'с репетитором', pretext: 'пробный урок', modal: MODAL_TYPES.freeLesson },
      ],
    },
  },
  [PAGE_TYPES.skills]: {
    courses: {
      title: 'курсы',
      items: [
        { name: 'курс «мой первый бизнес»', modal: MODAL_TYPES.skills },
        { name: 'скетчинг и рисование', modal: MODAL_TYPES.skills },
        { name: 'ИИ в учебе, хобби, работе', modal: MODAL_TYPES.skills },
        { name: 'блоггинг', modal: MODAL_TYPES.skills },
        { name: 'стриминг', modal: MODAL_TYPES.skills },
        { name: 'it-курсы программирования', to: '/catalog/programmirovanie-dlya-detej' },
        { name: 'графический дизайн', modal: MODAL_TYPES.skills },
        { name: 'цифровой дизайн', modal: MODAL_TYPES.skills },
        { name: 'основы создания комикса', modal: MODAL_TYPES.skills },
        { name: 'анимированные комиксы', modal: MODAL_TYPES.skills },
        { name: 'основы мультипликации', modal: MODAL_TYPES.skills },
      ],
    },
    directions: {
      title: 'направления',
      items: [
        { name: 'школа программистов', modal: MODAL_TYPES.modal14 },
        { name: 'детская школа анимации', modal: MODAL_TYPES.modal14 },
        { name: 'кино и телевидение', modal: MODAL_TYPES.modal14 },
        { name: 'детская школа бизнеса', modal: MODAL_TYPES.modal14 },
        { name: 'детская актерская школа', modal: MODAL_TYPES.modal14 },
        { name: 'детская модельная школа', modal: MODAL_TYPES.modal14 },
        { name: 'детская музыкальная школа', modal: MODAL_TYPES.modal14 },
        { name: 'школа танцев для детей', modal: MODAL_TYPES.modal14 },
        { name: 'игровая индустрия и&nbsp;киберспорт', modal: MODAL_TYPES.modal14 },
      ],
    },
    free: {
      title: 'бесплатно',
      items: [
        { name: 'хоббиориентация', to: '/proforientacia' },
      ],
    },
    soft: {
      title: 'гибкие навыки',
      items: [
        { name: 'тайм-менеджмент', modal: MODAL_TYPES.soft },
        { name: 'ораторское мастерство', modal: MODAL_TYPES.soft },
        { name: 'сверхпамять', modal: MODAL_TYPES.soft },
        { name: 'скорочтение', modal: MODAL_TYPES.soft },
        { name: 'лидерство', modal: MODAL_TYPES.soft },
        { name: 'образовательная коробка synergy box', modal: MODAL_TYPES.soft },
      ],
    },
  },
  [PAGE_TYPES.it]: {
    programming: {
      title: 'программирование',
      items: [
        { name: 'в Scratch', to: '/catalog/programmirovanie-dlya-detej/Scratch' },
        { name: 'на Python', to: '/catalog/programmirovanie-dlya-detej/Python' },
        { name: 'на JavaScript', to: '/catalog/programmirovanie-dlya-detej/JavaScript' },
        { name: 'на Java', to: '/catalog/programmirovanie-dlya-detej/Java' },
        { name: 'в Minecraft', to: '/catalog/programmirovanie-dlya-detej/Minecraft' },
        { name: 'в Roblox', to: '/catalog/programmirovanie-dlya-detej/Roblox' },
        { name: 'на C++', to: '/catalog/programmirovanie-dlya-detej/C++' },
        { name: 'в CoSpaces', to: '/catalog/programmirovanie-dlya-detej/CoSpaces' },
      ],
    },
    formats: {
      title: 'форматы',
      items: [
        { name: 'для начинающих', to: '/catalog/programmirovanie-dlya-detej/nachinayushchim' },
        { name: 'для опытных', to: '/catalog/programmirovanie-dlya-detej/prodvinutym' },
        { name: 'обучение с репетитором', to: '/catalog/programmirovanie-dlya-detej/repetitor' },
      ],
    },
    free: {
      title: 'бесплатно',
      items: [
        { name: 'по любому языку программирования', pretext: 'пробное занятие', modal: MODAL_TYPES.freePrograming },
      ],
    },
  },

  [PAGE_TYPES.class10]: {
    school: {
      title: 'школа',
      items: [
        { name: 'домашняя школа', to: '/online-school' },
        { name: 'it школа', to: '/school-university?class=2' },
        { name: 'физмат класс', to: '/online-school/fiziko-matematicheskij' },
        { name: 'гуманитарный класс', to: '/online-school/gumanitarnyj' },
        { name: 'лингвистический класс', to: '/online-school/lingvisticheskij' },
        { name: 'соц. эконом класс', to: '/online-school/socialno-ekonomicheskij' },
        { name: 'экстернат для детей', to: '/externat' },
      ],
    },
    courses: {
      title: 'курсы и услуги',
      items: [
        { name: 'it-курсы программирования', to: '/catalog/programmirovanie-dlya-detej' },
        { name: 'курсы бизнеса для детей', modal: MODAL_TYPES.skills },
        { name: 'курсы гибких навыков', modal: MODAL_TYPES.skills },
        { name: 'digital и дизайн курсы', modal: MODAL_TYPES.skills },
        { name: 'репетиторы по предметам', to: '/catalog/repetitor' },
        { name: 'помощь с д/з', to: '/catalog/repetitor/pomoch-s-dz' },
        { name: 'подготовка к олимпиадам', to: '/catalog/repetitor/olympiada' },
      ],
    },
    languages: {
      title: 'иностранные языки',
      items: [
        { name: 'английский язык', to: '/catalog/repetitor/anglijskij_yazyk' },
        { name: 'китайский язык', to: '/catalog/repetitor/kitajskij-yazyk' },
        { name: 'немецкий язык', to: '/catalog/repetitor/nemezkij-yazyk' },
        { name: 'испанский язык', to: '/catalog/repetitor/ispanskij-yazyk' },
      ],
    },
    ege: {
      title: 'егэ',
      items: [
        { name: 'курсы егэ', to: '/ege' },
        { name: 'репетитор по егэ', to: '/catalog/repetitor/ege' },
        { name: 'егэ и поступление', modal: MODAL_TYPES.vuz },
      ],
    },
    free: {
      title: 'бесплатно',
      items: [
        { name: 'доступ к&nbsp;платформе', pretext: 'бесплатный', modal: MODAL_TYPES.freeLesson },
        { name: 'профориентация', to: '/proforientacia' },
      ],
    },
  },
  [PAGE_TYPES.class8]: {
    school: {
      title: 'школа',
      items: [
        { name: 'домашняя школа', to: '/online-school' },
        { name: 'it школа', to: '/school-university?class=2' },
        { name: 'физмат класс', to: '/online-school/fiziko-matematicheskij' },
        { name: 'гуманитарный класс', to: '/online-school/gumanitarnyj' },
        { name: 'лингвистический класс', to: '/online-school/lingvisticheskij' },
        { name: 'соц. эконом класс', to: '/online-school/socialno-ekonomicheskij' },
        { name: 'экстернат для детей', to: '/externat' },
      ],
    },
    courses: {
      title: 'курсы и услуги',
      items: [
        { name: 'it-курсы программирования', to: '/catalog/programmirovanie-dlya-detej' },
        { name: 'курсы бизнеса для детей', modal: MODAL_TYPES.skills },
        { name: 'курсы гибких навыков', modal: MODAL_TYPES.skills },
        { name: 'digital и дизайн курсы', modal: MODAL_TYPES.skills },
        { name: 'репетиторы по предметам', to: '/catalog/repetitor' },
        { name: 'помощь с д/з', to: '/catalog/repetitor/pomoch-s-dz' },
        { name: 'подготовка к олимпиадам', to: '/catalog/repetitor/olympiada' },
      ],
    },
    languages: {
      title: 'иностранные языки',
      items: [
        { name: 'английский язык', to: '/catalog/repetitor/anglijskij_yazyk' },
        { name: 'китайский язык', to: '/catalog/repetitor/kitajskij-yazyk' },
        { name: 'немецкий язык', to: '/catalog/repetitor/nemezkij-yazyk' },
        { name: 'испанский язык', to: '/catalog/repetitor/ispanskij-yazyk' },
      ],
    },
    oge: {
      title: 'огэ',
      items: [
        { name: 'курсы огэ', to: '/oge' },
        { name: 'репетитор по огэ', to: '/catalog/repetitor/oge' },
        { name: 'огэ и поступление', modal: MODAL_TYPES.college },
      ],
    },
    free: {
      title: 'бесплатно',
      items: [
        { name: 'доступ к&nbsp;платформе', pretext: 'бесплатный', modal: MODAL_TYPES.freeLesson },
        { name: 'профориентация', to: '/proforientacia' },
      ],
    },
  },
  [PAGE_TYPES.class5]: {
    school: {
      title: 'школа',
      items: [
        { name: 'домашняя школа', to: '/online-school' },
        { name: 'it школа', to: '/school-university?class=2' },
        { name: 'физмат класс', to: '/online-school/fiziko-matematicheskij' },
        { name: 'гуманитарный класс', to: '/online-school/gumanitarnyj' },
        { name: 'лингвистический класс', to: '/online-school/lingvisticheskij' },
        { name: 'соц. эконом класс', to: '/online-school/socialno-ekonomicheskij' },
        { name: 'экстернат для детей', to: '/externat' },
      ],
    },
    courses: {
      title: 'курсы и услуги',
      items: [
        { name: 'it-курсы программирования', to: '/catalog/programmirovanie-dlya-detej' },
        { name: 'курсы бизнеса для детей', modal: MODAL_TYPES.skills },
        { name: 'курсы гибких навыков', modal: MODAL_TYPES.skills },
        { name: 'digital и дизайн курсы', modal: MODAL_TYPES.skills },
        { name: 'репетиторы по предметам', to: '/catalog/repetitor' },
        { name: 'помощь с д/з', to: '/catalog/repetitor/pomoch-s-dz' },
        { name: 'подготовка к олимпиадам', to: '/catalog/repetitor/olympiada' },
      ],
    },
    languages: {
      title: 'иностранные языки',
      items: [
        { name: 'английский язык', to: '/catalog/repetitor/anglijskij_yazyk' },
        { name: 'китайский язык', to: '/catalog/repetitor/kitajskij-yazyk' },
        { name: 'немецкий язык', to: '/catalog/repetitor/nemezkij-yazyk' },
        { name: 'испанский язык', to: '/catalog/repetitor/ispanskij-yazyk' },
      ],
    },
    free: {
      title: 'бесплатно',
      items: [
        { name: 'доступ к&nbsp;платформе', pretext: 'бесплатный', modal: MODAL_TYPES.freeLesson },
        { name: 'профориентация', to: '/proforientacia' },
      ],
    },
  },
  [PAGE_TYPES.class1]: {
    tutors: {
      title: 'репетиторы',
      items: [
        { name: 'помощь с д/з', to: '/catalog/repetitor/pomoch-s-dz' },
        { name: 'математика', to: '/catalog/repetitor/matematika' },
        { name: 'русский язык', to: '/catalog/repetitor/russkij-yazyk' },
        { name: 'чтение для детей', to: '/catalog/repetitor/literatura' },
        { name: 'чтение на английском', to: '/catalog/repetitor/anglijskij_yazyk' },
        { name: 'изобретательство', modal: MODAL_TYPES.triz },
        { name: 'английский язык', to: '/catalog/repetitor/anglijskij_yazyk' },
      ],
    },
    free: {
      title: 'бесплатно',
      items: [
        { name: 'с репетитором', pretext: 'пробный урок', modal: MODAL_TYPES.freeLesson },
      ],
    },
  },
  [PAGE_TYPES.kids]: {
    tutors: {
      title: 'репетиторы',
      items: [
        { name: 'к школе за год', modal: MODAL_TYPES.preschooler },
        { name: 'к школе за два года', modal: MODAL_TYPES.preschooler },
        { name: 'чтение для детей', modal: MODAL_TYPES.preschooler },
        { name: 'изобретательство', modal: MODAL_TYPES.triz },
        { name: 'английский язык', modal: MODAL_TYPES.preschooler },
      ],
    },
    free: {
      title: 'бесплатно',
      items: [
        { name: 'с репетитором', pretext: 'пробный урок', modal: MODAL_TYPES.triz },
      ],
    },
  },
  [PAGE_TYPES.adult]: {
    education: {
      title: 'образование',
      items: [
        { name: 'экстернат для взрослых', to: '/externat-dlya-vzroslyh' },
        { name: 'колледж синергия', modal: MODAL_TYPES.college },
        { name: 'университет синергия', modal: MODAL_TYPES.vuz },
        { name: 'курсы для взрослых', modal: MODAL_TYPES.course },
        { name: 'двойной диплом', modal: MODAL_TYPES.secondDiploma },
        { name: 'второе высшее', modal: MODAL_TYPES.secondEducation },
      ],
    },
    useful: {
      title: 'полезное',
      items: [
        { name: 'подбор и&nbsp;поступление в&nbsp;вуз', modal: MODAL_TYPES.vuz },
        { name: 'подбор и&nbsp;поступление в&nbsp;колледж', modal: MODAL_TYPES.college },
        { name: 'поступление за границу', modal: MODAL_TYPES.secondDiploma },
      ],
    },
    free: {
      title: 'бесплатно',
      items: [
        { name: 'профориентация', to: '/proforientacia' },
      ],
    },
  },
};

export default { PAGE_TYPES };
